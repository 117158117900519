<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-row align="stretch" justify="center">
          <v-col :cols="portrait ? 12 : 6" md="6">
            <video
              id="localVideo"
              :srcObject.prop="localStream"
              playsinline
              autoplay
              muted
              class="secondary"
            />
          </v-col>
          <v-col :cols="portrait ? 12 : 6" md="6">
            <video
              id="remoteVideo"
              :srcObject.prop="remoteStream"
              playsinline
              autoplay
              class="secondary"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-app-bar app bottom height="72" color="secondary">
      <v-row align="center" justify="center">
        <v-col cols="12" class="text-center">
          <v-tooltip top open-delay="400">
            <template v-slot:activator="{ on }">
              <v-btn
                id="readyButton"
                fab
                color="primary"
                :disabled="readyButtonDisabled"
                class="mx-1"
                @click="handleReady"
                v-on="on"
              >
                <v-icon :large="!readyButtonDisabled"> mdi-video-check </v-icon>
              </v-btn>
            </template>
            {{ $t("ready") }}
          </v-tooltip>

          <v-tooltip top open-delay="400">
            <template v-slot:activator="{ on }">
              <v-btn
                id="callButton"
                fab
                color="success"
                :disabled="callButtonDisbaled"
                class="mx-1"
                @click="handleCall"
                v-on="on"
              >
                <v-icon :large="!callButtonDisbaled"> mdi-phone </v-icon>
              </v-btn>
            </template>
            {{ $t("call") }}
          </v-tooltip>

          <v-tooltip top open-delay="400">
            <template v-slot:activator="{ on }">
              <v-btn
                id="hangupButton"
                fab
                color="error"
                :disabled="hangupButtonDisabled"
                class="mx-1"
                @click="handleHangup"
                v-on="on"
              >
                <v-icon :large="!hangupButtonDisabled"> mdi-phone-hangup </v-icon>
              </v-btn>
            </template>
            {{ $t("hangup") }}
          </v-tooltip>
        </v-col>
      </v-row>
    </v-app-bar>
  </v-app>
</template>

<script>
const signalR = require("@microsoft/signalr");

// Methods in the hub called by the front end
const OFFER = "Offer";
const ANSWER = "Answer";
const CANDIDATE = "Candidate";
const LOG_IN = "LogIn";

// Methods in the front end called by the hub
const ON_NEW_OFFER = "NewOffer";
const ON_NEW_ANSWER = "NewAnswer";
const ON_NEW_CANDIDATE = "NewCandidate";

// Peer connection event names
const TRACK = "track";
const ICE_CANDIDATE = "icecandidate";

export default {
  name: "Sample",

  data() {
    return {
      configuration: {
        iceServers: this.$config.iceServers
      },
      localStream: null,
      remoteStream: new MediaStream(),
      signalingConnection: null,
      peerConnection: null,
      readyButtonDisabled: true,
      callButtonDisbaled: true,
      hangupButtonDisabled: true,
      portrait: false
    };
  },

  watch: {
    /**
     * On change of the local stream, adds all tracks to the
     * creates peer connection.
     */
    localStream() {
      if (!this.localStream || !this.peerConnection) return;

      this.localStream
        .getTracks()
        .forEach(track => this.peerConnection.addTrack(track, this.localStream));
    }
  },

  /**
   * Initializes a new peer connection and a connection to the
   * hub (signaling channel). Afterwards, logs in to the hub.
   */
  async mounted() {
    const portrait = window.matchMedia("all and (orientation:portrait)");
    portrait.onchange = this.switchOrientation;
    this.switchOrientation(portrait);

    await this.establishSignalRConnection();
    this.initializePeerConnection();
  },

  methods: {
    /** Establishes a connection with the hub specified in the config file.
     *  If successful, it enables the ready button of the front end after
     *  a login to the hub.
     */
    async establishSignalRConnection() {
      this.signalingConnection = new signalR.HubConnectionBuilder()
        .withUrl(this.$config.hubUrl)
        .build();

      this.signalingConnection.on(ON_NEW_OFFER, this.processNewOffer);
      this.signalingConnection.on(ON_NEW_ANSWER, this.processNewAnswer);
      this.signalingConnection.on(ON_NEW_CANDIDATE, this.processNewCandidate);

      try {
        await this.signalingConnection.start();

        const userId = "B7A36852-E339-4405-98FA-36A7A0681600";
        const secret = "Geheim";

        await this.propagateLogIn(userId, secret);

        this.readyButtonDisabled = false;
      } catch (error) {
        console.error(error.toString());
      }
    },

    /** Initializes a fresh peer connection with the predefined
     *  event handlers.
     */
    initializePeerConnection() {
      this.peerConnection = new RTCPeerConnection(this.configuration);

      this.peerConnection.addEventListener(TRACK, event => this.addRemoteTrack(event.track));

      this.peerConnection.addEventListener(ICE_CANDIDATE, event => {
        if (event.candidate) this.propagateIceCandidate(event.candidate);
      });
    },

    /**
     * Processes an incoming offer from another peer and sends
     * back an answer.
     * @param {RTCSessionDescriptionInit} offer A new offer to be processed
     */
    async processNewOffer(offer) {
      if (!offer || offer.type !== "offer") throw new Error("The provided offer is invalid.");

      const remoteDescription = new RTCSessionDescription(offer);
      await this.peerConnection.setRemoteDescription(remoteDescription);

      const answer = await this.peerConnection.createAnswer();
      await this.peerConnection.setLocalDescription(answer);
      await this.propagateAnswer(answer);
    },

    /**
     * Processes an incoming answer from another peer.
     * @param {RTCSessionDescriptionInit} answer A new answer to be processed
     */
    async processNewAnswer(answer) {
      if (answer) {
        const remoteDescription = new RTCSessionDescription(answer);
        await this.peerConnection.setRemoteDescription(remoteDescription);
      }
    },

    /**
     * Processes an incoming ICE candidate from another peer.
     * @param {RTCIceCandidateInit} iceCandidate A new ICE candidate to be processed
     */
    async processNewCandidate(iceCandidate) {
      if (iceCandidate) {
        try {
          await this.peerConnection.addIceCandidate(iceCandidate);
        } catch (e) {
          console.error("Error adding received ice candidate", e);
        }
      }

      return;
    },

    /**
     * Upon a click on the ready button, tries to get user media
     * (audio and video) from this client and displays them locally.
     * Afterwards, Enables the call button.
     */
    async handleReady() {
      this.readyButtonDisabled = true;

      try {
        let stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true
        });

        this.localStream = stream;
        this.callButtonDisbaled = false;
      } catch (error) {
        console.error(`getUserMedia() error: ${error.name}`);
      }
    },

    /**
     *  Upon a click on the call button, creates an offer for other
     *  peers and sends them via the signaling channel.
     */
    async handleCall() {
      this.callButtonDisbaled = true;
      this.hangupButtonDisabled = false;

      const offer = await this.peerConnection.createOffer();
      await this.peerConnection.setLocalDescription(offer);
      await this.propagateOffer(offer);
    },

    /**
     * Disposes the peer connection.
     */
    handleHangup() {
      // renew remote media stream
      this.stopStream(this.remoteStream);
      this.remoteStream = new MediaStream();

      // dispose local media stream
      this.stopStream(this.localStream);
      this.localStream = null;

      // renew connection
      if (this.peerConnection) this.peerConnection.close();
      this.initializePeerConnection();

      // handle buttons
      this.hangupButtonDisabled = true;
      this.readyButtonDisabled = false;
    },

    /**
     * Stops the given media stream.
     * @param {MediaStream} stream - The stream that shall be stopped
     */
    stopStream(stream) {
      if (stream) stream.getTracks().forEach(track => track.stop());
    },

    /** Adds the given track to the remote stream. */
    addRemoteTrack(track) {
      if (!track) throw new Error("Only valid tracks can be added as remote tracks.");

      this.remoteStream.addTrack(track, this.remoteStream);
    },

    /** Login to the hub.
     *  @param {string} userId GUID for the user
     *  @param {string} secret Secret of the user
     */
    async propagateLogIn(userId, secret) {
      console.debug("propagateLogIn");

      try {
        await this.signalingConnection.invoke(LOG_IN, userId, secret);
      } catch (error) {
        console.error(error.toString());
      }
    },

    /**
     * Propagates the given ICE candidate using the signaling channel (hub).
     * @param {RTCIceCandidateInit} candidate ICE candidate
     */
    async propagateIceCandidate(candidate) {
      console.debug("propagateIceCandidate");
      if (!candidate) throw new Error("Only valid ICE candidates can be propagated.");

      try {
        await this.signalingConnection.invoke(CANDIDATE, candidate);
      } catch (error) {
        console.error(error.toString());
      }
    },

    /**
     * Propagates the given offer using the signaling channel (hub).
     * @param {RTCSessionDescriptionInit} offer Offer
     */
    async propagateOffer(offer) {
      console.debug("propagateOffer");
      if (!offer || offer.type !== "offer") throw new Error("The provided offer is invalid.");

      try {
        await this.signalingConnection.invoke(OFFER, offer);
      } catch (error) {
        console.error(error.toString());
      }
    },

    /**
     * Propagates the given answer using the signaling channel (hub).
     * @param {RTCSessionDescriptionInit} answer Answer
     */
    async propagateAnswer(answer) {
      console.debug("propagateAnswer");
      if (!answer || answer.type !== "answer") throw new Error("The provided answer is invalid.");

      try {
        await this.signalingConnection.invoke(ANSWER, answer);
      } catch (error) {
        console.error(error.toString());
      }
    },

    /**
     * Reacts to changes in the display orientation.
     * @param {MediaQueryList | MediaQueryListEvent} event
     * @returns {void}
     */
    switchOrientation(event) {
      this.portrait = event.matches;
    }
  }
};
</script>

<style>
@font-face {
  font-family: "Cera";
  src: url("/fonts/cera/cera-round-pro-medium.otf") format("opentype");
}

.font-family-cera {
  font-family: "Cera";
}

*,
html,
body {
  caret-color: transparent;
}

video {
  --width: 100%;
  width: var(--width);
  max-height: 40vh;
  vertical-align: top;
  background: url("/img/icons/mstile-310x310.png") no-repeat center;
  background-size: contain;
}
</style>

<i18n>
{
  "de": {
    "ready": "Bereit",
    "call": "Anrufen",
    "hangup": "Auflegen"
  },
  "en": {
    "ready": "Ready",
    "call": "Call",
    "hangup": "Hang up"
  }
}
</i18n>
